import React, {useState} from 'react';
import AppNavbar from './AppNavbar';
import './Login.css';
import QRCode from "react-qr-code";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Button, FormGroup} from "reactstrap";

export default function InitMfa() {
    const location = useLocation();
    const {mfaKey, username, password} = location.state;
    console.log("Key:" + mfaKey);
    console.log("username:" + username);
    console.log("password:" + password);
    const [codiceOtp, setCodiceOtp] = useState();
    const [error, setError] = useState();
    const history = useHistory();
    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            username,
            password,
            codiceOtp
        });

        if (response.token != null && response.userId != null) {

            sessionStorage.setItem('token', response.token);
            sessionStorage.setItem('userId', response.userId);
            sessionStorage.setItem('locId', response.locId);
            sessionStorage.setItem('accId', response.accId);
            sessionStorage.setItem('username', response.username);
            sessionStorage.setItem('awp', response.awp);
            history.push('/users');
        } else {
            const errorData = response.errorDesc;
            setError(errorData);
        }
    }

    async function loginUser(credentials) {
        return fetch('/B2BUserManager/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json())
    }

    return (
        <div>
            <AppNavbar/>
            <br/>

            <div className="login-wrapper">
                <br/><h2>Inizializza MFA scansionando il QR Code e inserisci il codice OTP</h2>
                <br/>
                <QRCode
                    size={256}
                    value={mfaKey}
                    viewBox={`0 0 256 256`}/>

                <br/>

                <div className="login-wrapper">
                    <form onSubmit={handleSubmit}>
                        <label style={{textAlign: "center"}}>
                            <h5>Codice OTP</h5>
                            <input type="text" onChange={e => setCodiceOtp(e.target.value)}/>
                        </label><br/><br/>
                        <p/>
                        <div align="center">
                            <FormGroup>
                                <Button color="success" type="submit"> Entra</Button>{' '}
                                <Button color="secondary" tag={Link} to="/login">Chiudi</Button>
                            </FormGroup>
                        </div>
                        <br/>
                        <div align="center">
                            {error ? <label style={{color: 'red'}}>{error}</label> : null}
                        </div>
                    </form>
                </div>

            </div>


        </div>
    )
}







