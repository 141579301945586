import React, {Component, useState} from 'react';
import AppNavbar from './AppNavbar';
import './Login.css';
import PropTypes from "prop-types";
import {Link, useHistory} from "react-router-dom";
import {Button, ButtonGroup, Input, Label} from "reactstrap";
import InitMfa from "./InitMfa";
import Otp from "./Otp";

async function loginUser(credentials) {
    return fetch('/B2BUserManager/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

async function initMfaUser(credentials) {
    return fetch('/B2BUserManager/users/initMFA', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

async function CheckAuth(credential) {
    return fetch('/B2BUserManager/users/checkAuth', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credential)
    })
        .then(data => data.json())
}

async function Auth() {
    const history = useHistory();
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');

    if (token || userId) {
        const response = await CheckAuth({
            token,
            userId
        });

        if (response.autorizzato === true) {
            history.push('/users');
        }
    }
}

export default function Login() {
    Auth();
    const history = useHistory();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [errorEmail, setErrorMail] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            username,
            password
        });

        if(response.errorCode == "701"){
            console.log("Error 701 ---- Init Mfa " + username + "-" + password);
            const responseMfa = await initMfaUser({
                username,
                password
            });

            if(responseMfa.resultCode != null && responseMfa.resultCode == 0 ){
                console.log("ResponseMfa:" + responseMfa.key);
                const mfaKey = responseMfa.key;
                history.push({
                    pathname: '/initMfa',
                    state:  {mfaKey, username, password}
                });
            }
        } else if (response.errorCode == "702") {
            console.log("Error 702 ---- Inserimento Otp " + username + "-" + password);
            history.push({
                pathname: '/otp',
                state: { username , password }
            });
        } else if (response.token != null && response.userId != null) {

            sessionStorage.setItem('token', response.token);
            sessionStorage.setItem('userId', response.userId);
            sessionStorage.setItem('locId', response.locId);
            sessionStorage.setItem('accId', response.accId);
            sessionStorage.setItem('username', response.username);
            sessionStorage.setItem('awp', response.awp);
            history.push('/users');
        } else {
            const errorData = response.errorDesc;
            setError(errorData);
        }
    }

    return (
        <div>
            <AppNavbar/>
            <br/>

            <div className="login-wrapper">
                <h2>Login Utente</h2>
                <form onSubmit={handleSubmit}>
                    <label style={{textAlign: "center"}}>
                        <h5>Username</h5>
                        <input type="text" onChange={e => setUserName(e.target.value)}/>
                    </label><br/><br/>
                    <label style={{textAlign: "center"}}>
                        <h5>Password</h5>
                        <input type="password" onChange={e => setPassword(e.target.value)}/>
                    </label>

                    <p/>
                    <div>
                        <Button color="success" style={{width: "188px", height: "38px",}} type="submit"> Entra</Button>
                    </div>
                    <br/>
                    <div>
                        {error ? <label style={{color: 'red', textAlign: "center"}}>{error}</label> : null}
                    </div>
                    <div align="center">
                        <Button color="link" tag={Link} to="/recovery">Recupera Password</Button>
                    </div>
                </form>
            </div>
        </div>

    )
}




