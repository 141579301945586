import React, {Component, useState} from 'react';
import AppNavbar from './AppNavbar';
import './Login.css';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Button, FormGroup} from "reactstrap";

async function loginUser(credentials) {
    return fetch('/B2BUserManager/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Otp() {
    const location = useLocation();
    const history = useHistory();
    const {username, password} = location.state;
    const [codiceOtp, setCodiceOtp] = useState();
    const [error, setError] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            username,
            password,
            codiceOtp
        });

        if (response.token != null && response.userId != null) {

            sessionStorage.setItem('token', response.token);
            sessionStorage.setItem('userId', response.userId);
            sessionStorage.setItem('locId', response.locId);
            sessionStorage.setItem('accId', response.accId);
            sessionStorage.setItem('username', response.username);
            sessionStorage.setItem('awp', response.awp);
            history.push('/users');
        } else {
            const errorData = response.errorDesc;
            setError(errorData);
        }
    }

    return (
        <div>
            <AppNavbar/>
            <br/>

            <div className="login-wrapper">
                <form onSubmit={handleSubmit}>
                    <label style={{textAlign: "center"}}>
                        <h5>Codice OTP</h5>
                        <input type="text" onChange={e => setCodiceOtp(e.target.value)}/>
                    </label><br/><br/>
                    <p/>
                    <div align="center">
                        <FormGroup>
                            <Button color="success" type="submit"> Entra</Button>{' '}
                            <Button color="secondary" tag={Link} to="/login">Chiudi</Button>
                        </FormGroup>
                    </div>
                    <br/>
                    <div align="center">
                        {error ? <label style={{color: 'red'}}>{error}</label> : null}
                    </div>
                    <div align="center">
                        <Button color="link" tag={Link} to="/resetMfa">Reset MFA</Button>
                    </div>
                </form>
            </div>

        </div>

    )
}




