import React, {Component, useState} from 'react';
import {Link, withRouter, useHistory} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label, DropdownMenu} from 'reactstrap';
import AppNavbar from './AppNavbar';

class UserEdit extends Component {

    emptyItem = {
        username: '',
        email: '',
        nome: '',
        cognome: '',
        profilo: "1",
        sospeso: false,
        validationError: '',
        modifica: true
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /*async componentDidMount() {
        this.Auth();
        if (this.props.match.params.id !== 'new') {

            const user = await (await fetch(`/B2BUserManager/users/${this.props.match.params.id}`)).json();
            this.setState({item: user});

            if (user.profilo == "1") {
                document.getElementById("divDelega").style.display = "none";
            }
            if (user.delega === true) {
                document.getElementById('delega').checked = true;
            } else {
                document.getElementById('delega').checked = false;
            }

            if (user.sospeso === true) {
                document.getElementById('sospeso').checked = true;
            } else {
                document.getElementById('sospeso').checked = false;
            }
        } else {
            document.getElementById("divDelega").style.display = "none";
        }
    }*/

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            const id = this.props.match.params.id;
            console.log("Id User Edit:" + id);
            const locId = sessionStorage.getItem('locId');
            const userId = sessionStorage.getItem('userId');
            const token = sessionStorage.getItem('token');
            fetch('/B2BUserManager/users/detail',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({id,userId,token,locId})
            })
                .then(response => response.json())
                .then(data => {
                    const autorizzato = data.autorizzato
                    console.log(autorizzato);
                    if (autorizzato === true) {
                        console.log("is Autorizzato Edit");
                        //this.setState({item: data.dettaglioUtente});
                        console.log("Sospeso:" + data.dettaglioUtente.sospeso);
                        this.setState({item: data.dettaglioUtente});

                        if (data.dettaglioUtente.sospeso === true) {
                            document.getElementById('sospeso').checked = true;
                        } else {
                            document.getElementById('sospeso').checked = false;
                        }
                    } else {
                        console.log("is not Autorizzato Edit");
                        this.props.history.push('/login');
                    }
                });

        } else {
            const token = sessionStorage.getItem('token');
            const userId = sessionStorage.getItem('userId');
            if(!token && !userId) {
                this.props.history.push('/login');
            }
        }
    }


    async logout() {
        const token = sessionStorage.getItem('token');
        await fetch('/B2BUserManager/users/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token}),
        })

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('locId');
        sessionStorage.removeItem('accId');
        sessionStorage.removeItem('awp');
        this.props.history.push('/');
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        const locId = sessionStorage.getItem('locId');
        item.locId = locId;
        const accId = sessionStorage.getItem('accId');
        item.accId = accId;
        const userId = sessionStorage.getItem('userId');
        item.userId = userId;
        const usernameOp = sessionStorage.getItem('username');
        item.usernameOp = usernameOp;
        const token = sessionStorage.getItem('token');
        item.token = token;
        const awp = sessionStorage.getItem('awp');
        item.awp = awp;

        if (document.getElementById("sospeso").checked) {
            item.sospeso = true;
        } else {
            item.sospeso = false;
        }
        this.setState({item});

        if (item.username == '') {
            this.props.history.push('/users' + (item.idUtente ? '/' + item.idUtente : '/new'));
            item.validationErrorUsername = "Il campo Username non può essere vuoto";
        } else if (item.email == '') {
            item.validationErrorUsername = null;
            this.props.history.push('/users' + (item.idUtente ? '/' + item.idUtente : '/new'));
            item.validationErrorEmail = "Il campo Email non può essere vuoto";
        } else if (item.nome == '') {
            item.validationErrorUsername = null;
            item.validationErrorEmail = null;
            this.props.history.push('/users' + (item.idUtente ? '/' + item.idUtente : '/new'));
            item.validationErrorNome = "Il campo Nome non può essere vuoto";
        } else if (item.cognome == '') {
            item.validationErrorUsername = null;
            item.validationErrorEmail = null;
            item.validationErrorNome = null;
            this.props.history.push('/users' + (item.idUtente ? '/' + item.idUtente : '/new'));
            item.validationErrorCognome = "Il campo Cognome non può essere vuoto";
        } else {
            item.validationErrorUsername = null;
            item.validationErrorEmail = null;
            item.validationErrorCognome = null;
            item.validationErrorNome = null;
            await fetch('/B2BUserManager/users/save' + (item.idUtente ? '/' + item.idUtente : ''), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(item),
            }) .then(response => response.json())
                .then(data => {
                    const resultDesc = data.resDesc;
                    const resultCode = data.resCode;
                    console.log("Result Desc:" + resultDesc);
                    console.log("Result Code:" + resultCode);
                    if(resultDesc=="Nome utente già esistente" || resultCode===1000){
                        this.props.history.push('/users' + (item.idUtente ? '/' + item.idUtente : '/new'));
                        item.validationErrorUsername = resultDesc;
                    }
                });

            if(item.validationErrorUsername != "Nome utente già esistente" && item.validationErrorUsername != "Username non valido - caratteri speciali ammessi . _ -"){
                this.props.history.push('/users');
            }
        }
    }

     getProfilo (profilo) {
        if(profilo == 0) {
            return <select className="form-select" aria-label=".form-select-lg example" name="profilo" id="profilo"
                           value={profilo}
                           onChange={this.handleChange}>
                <option value="0">Master</option>
            </select>;
        } else {
            return <select className="form-select" aria-label=".form-select-lg example" name="profilo" id="profilo"
                           value={profilo}
                           onChange={this.handleChange}>
                <option value="1">Responsabile</option>
                <option value="2">Collaboratore</option>
            </select>;
        }
     }

    render() {

        const {item} = this.state;
        let disableModifica=false;

        if(!item.modifica){
            disableModifica = true;
        }

        const title = item.idUtente ? 'Modifica Utente' : 'Nuovo Utente';
        const title1 = item.modifica ? title : 'Dettaglio Utente';
        return <div>
            <AppNavbar/>
            <Container>
                <br/>
                <div align="center">
                    <h4>{title1}</h4>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Username</Label>
                        <Input type="text" name="username" id="username" value={item.username || ''}
                               onChange={this.handleChange} autoComplete="username"/>
                        <div>
                            {item.validationErrorUsername ? <label style={{
                                color: 'red',
                                textAlign: "center"
                            }}>{item.validationErrorUsername}</label> : null}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" value={item.email || ''}
                               onChange={this.handleChange} autoComplete="email"/>
                        <div>
                            {item.validationErrorEmail ? <label
                                style={{color: 'red', textAlign: "center"}}>{item.validationErrorEmail}</label> : null}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <Label for="nome">Nome</Label>
                        <Input type="text" name="nome" id="nome" value={item.nome || ''}
                               onChange={this.handleChange} autoComplete="nome"/>
                        <div>
                            {item.validationErrorNome ? <label
                                style={{color: 'red', textAlign: "center"}}>{item.validationErrorNome}</label> : null}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <Label for="cognome">Cognome</Label>
                        <Input type="text" name="cognome" id="cognome" value={item.cognome || ''}
                               onChange={this.handleChange} autoComplete="cognome"/>
                        <div>
                            {item.validationErrorCognome ? <label style={{
                                color: 'red',
                                textAlign: "center"
                            }}>{item.validationErrorCognome}</label> : null}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <Label for="profilo">Ruolo</Label>
                        {this.getProfilo(item.profilo)}
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <div id="divSospendi">
                            <input type="checkbox" id="sospeso" onChange={this.handleChange}/> Utente Sospeso
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <br/>
                        <Button disabled={disableModifica} color="success" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/users">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(UserEdit);